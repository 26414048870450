.flexcc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexcb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blur-back {
    backdrop-filter: blur(10px) saturate(180%);
    -webkit-backdrop-filter: blur(10px) saturate(180%);
}

.d-flex {
    display: flex;
}

.flexc {
    display: flex;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.outline-none {
    outline: none !important;
}

.w-100 {
    width: 100%;
}

a {
    color: inherit;
}

h1 {
    margin: 0px;
}

input {
    outline: none;
}

.invert {
    filter: invert(100%)
}

.smooth-scroll {
    scroll-behavior: smooth;
}

.no-scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none
}

.transpanet-input {
    outline: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    height: 100%;
    width: 100%
}

.white {
    color: #fff;
}

.black {
    color: #202020;
}

.required::after {
    content: "*";
    color: #ee5050;
    padding: 2px;
}

.round-image {
    border-radius: 50%;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-2 {
    opacity: 0.2;
}

///////////
.text-smallest {
    font-size: 10px;
}

.text-smaller {
    font-size: 12px;
}

.text-small {
    font-size: 14px;
}

.text-normal {
    font-size: 16px;
}

.text-semibig {
    font-size: 18px;
}

.text-big {
    font-size: 22px;
}

.text-ultra-big {
    font-size: 28px;
}

.text-mega-big {
    font-size: 45px;
}

.text-center {
    text-align: center !important;
}

.text-start {
    text-align: start;
}

.spacing-small {
    letter-spacing: 2px;
}

.font-bold {
    font-weight: bold;
}

/////
.border-radius-4 {
    border-radius: 4px;
}

.border-radius-8 {
    border-radius: 8px;
}

.border-radius-10 {
    border-radius: 10px;
}

.border-radius-15 {
    border-radius: 15px;
}

.border-radius-25 {
    border-radius: 25px;
}

///////////
* {
    box-sizing: border-box;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    width: 100%;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

button {
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

p {
    margin: 0px;
}

///////////
.rtl {
    direction: rtl !important;
    *:not(i) {
        font-family: 'iranyekan', sans-serif !important;
    }
    .mld-1 {
        margin-right: 2px
    }
    .mrd-1 {
        margin-left: 2px
    }
    .mld-2 {
        margin-right: 5px
    }
    .mrd-2 {
        margin-left: 5px
    }
    .mld-3 {
        margin-right: 8px
    }
    .mrd-3 {
        margin-left: 8px
    }
    .mld-4 {
        margin-right: 12px
    }
    .mrd-4 {
        margin-left: 12px
    }
    .mld-10 {
        margin-right: 40px
    }
    .mrd-10 {
        margin-left: 40px
    }
    .search-border {
        border-left: 1px solid #ddd
    }
}

.ltr {
    direction: ltr !important;
    * {
        font-family: 'Poppins', 'iranyekan', sans-serif;
    }
    .mld-1 {
        margin-left: 2px
    }
    .mrd-1 {
        margin-right: 2px
    }
    .mld-2 {
        margin-left: 5px
    }
    .mrd-2 {
        margin-right: 5px
    }
    .mld-3 {
        margin-left: 8px
    }
    .mrd-3 {
        margin-right: 8px
    }
    .mld-4 {
        margin-left: 12px
    }
    .mrd-4 {
        margin-right: 12px
    }
    .mld-10 {
        margin-left: 40px
    }
    .mrd-10 {
        margin-right: 40px
    }
    .search-border {
        border-right: 1px solid #ddd
    }
}

.mt-1 {
    margin-top: 2px;
}

.mt-2 {
    margin-top: 5px;
}

.mt-3 {
    margin-top: 10px;
}

.mt-4 {
    margin-top: 15px;
}

.mt-5 {
    margin-top: 20px;
}

.mb-4 {
    margin-bottom: 20px;
}

.ph-3 {
    padding-left: 10px;
    padding-right: 10px;
}