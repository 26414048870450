.section-base {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-right: auto;
    margin-left: auto;
}

.profile-image {
    border-radius: 6px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    background-size: cover;
    background-position: center,
}

.button-light-hover {
    transition: all 0.5s;
}

.button-light-hover:hover {
    background-color: #FF6C0010 !important;
    border-left: 3px solid #FF6C00;
}

// .button-light-hover:active {
//     background-color: #FF6C0020 !important;
//     border-left: 3px solid #FF6C00;
// }
.button-light-hover1 {
    transition: all 0.5s;
}

.button-light-hover1:hover {
    background-color: #FF6C0010 !important;
    // border-left: 3px solid #FF6C00;
}

.profileImageInput {
    .profileImageButton {
        background-color: #e2e8f2;
        border: 1px solid #f0f2f4;
        border-radius: 30px;
        padding: 7px 20px;
        box-shadow: inset 0 0 5px #007aff10;
    }
    .profileImageRemoveButton {
        color: #ee5050;
        font-size: 12px;
    }
}

.profileImageInputContainer {
    object-fit: cover;
    width: 120px;
    height: 120px;
    position: relative;
}

.profileImage {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallary {
    .gallary-arrow {
        opacity: 0.3;
        transition: all 0.5s;
        &:hover {
            opacity: 0.9;
        }
    }
    .gallary-image {
        opacity: 0.6;
        transition: all 0.5s;
        &:hover {
            opacity: 1;
        }
        &.active {
            opacity: 1;
        }
    }
}

.transition-all {
    transition: all 0.5s;
}

.hover-back:hover {
    background-color: #f2f6f8;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 0px white inset !important;
    -webkit-text-fill-color: #333;
}

@-webkit-keyframes autofill {
    0%,
    100% {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s;
    /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.page-loader table {
    table-layout: fixed;
    width: 100%;
}

.page-loader figure {
    margin: 0px;
}

.page-loader img {
    width: 100% !important
}

.page-loader table {
    border-spacing: 0em;
    margin-top: 15px;
    margin-bottom: 25px;
}

.page-loader td {
    border: 1px solid #f2f6f8;
    margin: 0px;
    padding: 5px 10px;
}

.page-loader tr:nth-child(even) {
    background-color: #f6f8fa;
}

.rtl {
    .page-loader {
        direction: rtl;
    }
}

.ltr {
    .page-loader {
        direction: ltr;
    }
}

.center-marker {
    width: 25px;
    height: 43px;
    margin-top: -43px;
    margin-left: -12.5px;
    top: 50%;
    left: 50%;
    position: absolute;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSI0MyI+PHBhdGggZD0iTTExLjUgMGgxLjkyQzE5LjQ4LjQ1IDI0LjU4IDUuNjIgMjUgMTEuNjh2MS41OGMtLjcyIDcuOTYtOC41NiAxMi42My0xMC4yOCAyMC4xOS0uNDEgMS42MS0uNjUgMy4yNi0uODcgNC45MS0uMjUuODctLjc0IDIuNjItLjk4IDMuNDktMS4wNC0uOTEtMS41OS0yLjA3LTEuNjQtMy40Ny0uNTctMy41Ni0xLjI4LTcuMjEtMy4zNC0xMC4yNUM0LjgxIDIzLjU4Ljc2IDE5LjI3IDAgMTMuNjF2LTEuOTRDLjM3IDUuNjQgNS40OC40NSAxMS41IDBtLTEuMiA4LjMyYy0zLjA2IDEuMzYtMy4yOSA2LjExLS42NCA4IDIuNjggMi4wNSA3LjQyLjIzIDcuNC0zLjM0LjY3LTMuNjMtMy42MS02LjM5LTYuNzYtNC42NnoiIGZpbGw9IiNlYTM2MzciLz48cGF0aCBkPSJNOC44IDQwLjQ0Yy43OC0uNzEgMS41OS0xLjQgMi40My0yLjA2LjA1IDEuNC42IDIuNTYgMS42NCAzLjQ3LjI0LS44Ny43My0yLjYyLjk4LTMuNDkuODkuNyAxLjc1IDEuNDQgMi41OCAyLjIxLS4yNC44NC0uNjIgMS42My0uOTYgMi40M0g5LjY4Yy0uMzEtLjg1LS42Ni0xLjY4LS44OC0yLjU2eiIgb3BhY2l0eT0iLjE4Ii8+PC9zdmc+");
}

.marker {
    width: 25px;
    height: 43px;
    // transform: translate(12px, 43px);
    filter: grayscale(100%);
    // margin-top: -43px;
    // margin-left: -12.5px;
    top: 0;
    left: 0;
    position: absolute;
    background-size: cover;
    // width: 50px;
    // height: 50px;
    // border-radius: 50%;
    // cursor: pointer;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSI0MyI+PHBhdGggZD0iTTExLjUgMGgxLjkyQzE5LjQ4LjQ1IDI0LjU4IDUuNjIgMjUgMTEuNjh2MS41OGMtLjcyIDcuOTYtOC41NiAxMi42My0xMC4yOCAyMC4xOS0uNDEgMS42MS0uNjUgMy4yNi0uODcgNC45MS0uMjUuODctLjc0IDIuNjItLjk4IDMuNDktMS4wNC0uOTEtMS41OS0yLjA3LTEuNjQtMy40Ny0uNTctMy41Ni0xLjI4LTcuMjEtMy4zNC0xMC4yNUM0LjgxIDIzLjU4Ljc2IDE5LjI3IDAgMTMuNjF2LTEuOTRDLjM3IDUuNjQgNS40OC40NSAxMS41IDBtLTEuMiA4LjMyYy0zLjA2IDEuMzYtMy4yOSA2LjExLS42NCA4IDIuNjggMi4wNSA3LjQyLjIzIDcuNC0zLjM0LjY3LTMuNjMtMy42MS02LjM5LTYuNzYtNC42NnoiIGZpbGw9IiNlYTM2MzciLz48cGF0aCBkPSJNOC44IDQwLjQ0Yy43OC0uNzEgMS41OS0xLjQgMi40My0yLjA2LjA1IDEuNC42IDIuNTYgMS42NCAzLjQ3LjI0LS44Ny43My0yLjYyLjk4LTMuNDkuODkuNyAxLjc1IDEuNDQgMi41OCAyLjIxLS4yNC44NC0uNjIgMS42My0uOTYgMi40M0g5LjY4Yy0uMzEtLjg1LS42Ni0xLjY4LS44OC0yLjU2eiIgb3BhY2l0eT0iLjE4Ii8+PC9zdmc+");
}

.mapboxgl-ctrl-attrib-inner {
    display: none !important;
}

canvas {
    outline: none;
}

.mapboxgl-canvas {
    position: relative;
}

.body-no-sroll {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    height: 100vh;
}

.subscribe-input::placeholder {
    color: rgba(253, 121, 45, 1);
}

.product-box {
    border: 1px solid #f4f8fa;
    .product-view-but {
        border: 1px solid #FF6C00;
        border-radius: 20px;
        padding: 5px 10px;
        color: #FF6C00;
        transition: all 0.5s;
    }
    &:hover {
        .product-view-but {
            background-color: #FF6C00;
            color: #fff;
        }
    }
}

.search-but {
    border-radius: 20px 0px 0px 20px,
}

@media (max-width: 768px) {
    .search-but {
        border-radius: 20px;
        margin: 10px 10px;
    }
    .search-field {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .footer-back {
        border-radius: 0px !important;
    }
}