.fadein {
    animation: fadein 0.5s;
}

.fadeout {
    animation: fadeout 0.5s;
}

.glow {
    // font-size: 80px;
    // color: #fff;
    // text-align: center;
    -webkit-animation: glow 0.6s ease-in-out infinite alternate;
    -moz-animation: glow 0.6s ease-in-out infinite alternate;
    animation: glow 0.6s ease-in-out infinite alternate;
    // box-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
}

.glow-static {
    border: 1px solid rgb(86, 180, 239);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes glow {
    from {
        border: 1px solid #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.0) inset, 0px 0px 8px rgba(82, 168, 236, 0);
        // box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        border: 1px solid rgb(86, 180, 239);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
        // box-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
}