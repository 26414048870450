.header {
    .language-but {
        background-color: transparent;
        border: #f1f2f4 0px solid;
        border-radius: 20px;
        font-size: 14px;
        // font-weight: 600;
        // color: #fff;
        padding: 2px 8px 2px 4px;
    }
    .language-select-box {
        position: absolute;
        top: 30px;
        background-color: #f2f6f8dd;
        padding: 15px 15px;
        border-radius: 8px;
        .ltr & {
            right: 0px;
        }
        .rtl & {
            left: 0px;
        }
    }
    .language-select-option {
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0px;
        img {
            height: 20px;
        }
    }
}

.default-input-view {
    width: 100%;
    border: 1px solid #f2f4f6;
    height: 45px;
    padding: 5px 10px;
    border-radius: 2px;
    background-color: #fff;
}

.modern-input {
    width: 100%;
    border: 0px solid #f1f2f4;
    background-color: #f2f6f8;
    min-height: 40px;
    padding: 5px 10px;
    border-radius: 8px;
    box-shadow: inset 0 0 5px #eee;
    & input {
        font-size: 15px;
        &::placeholder {
            color: #9ab;
        }
    }
    & textarea {
        font-size: 15px;
        &::placeholder {
            color: #9ab;
        }
    }
}

.transition-all {
    transition: all 0.5s;
}

.placeholder {
    font-size: 15px;
    color: #9ab;
}

.fa-arrow-right {
    .rtl & {
        transform: rotate(180deg);
    }
}

input[type="checkbox"] {
    /* change "blue" browser chrome to yellow */
    filter: invert(0%) hue-rotate(-200deg) brightness(1.4);
    transform: scale(1.2, 1.2);
}